<template>
	<!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
	<div style="height: inherit">
		<div
			class="body-content-overlay"
			:class="{ show: mqShallShowLeftSidebar }"
			@click="mqShallShowLeftSidebar = false"
		/>

		<!-- Email List -->
		<div class="email-app-list">
			<webinar-view
				v-if="editWebinar"
				:class="{ show: editWebinar }"
				:email-view-data="emailViewData"
				:opended-email-meta="opendedEmailMeta"
				@move-email-to-folder="moveOpenEmailToFolder"
				@toggle-email-starred="toggleStarred(emailViewData)"
				@update-email-label="updateOpenEmailLabel"
				@mark-email-unread="markOpenEmailAsUnread"
				@change-opened-email="changeOpenedEmail"
			/>
			<!-- Emails List -->
			<vue-perfect-scrollbar
				:settings="perfectScrollbarSettings"
				class="email-user-list scroll-area"
				v-if="showParticipants"
			>
				<ul class="email-media-list">
					<!-- TODO UpdateEmailViewDate -->
					<b-media
						v-for="email in emails"
						:key="email.id"
						tag="li"
						no-body
						@click="updateEmailViewData(email)"
					>
						<b-media-body>
							<div class="mail-details">
								<div class="mail-items">
									<h5 class="mb-25">
										{{ email.firstname }} {{ email.lastname }} -
										<em>{{ email.email }}</em>
									</h5>
								</div>
							</div>
							<div class="row">
								<div class="d-flex justify-content-between  col-7 ml-3">
									<div>
										<h3 class="mb-0">
											<span
												class="text-muted font-weight-bolder "
												style="font-size:15px"
												>Pre-Quiz
											</span>
											<b-avatar variant="light-success" size="34">
												80%
											</b-avatar>
										</h3>
									</div>
									<div>
										<h3 class="mb-0">
											<span
												class="text-muted font-weight-bolder "
												style="font-size:15px"
												>Post-Quiz
											</span>
											<b-avatar variant="light-warning" size="34">
												<feather-icon icon="XCircleIcon" size="25" />
											</b-avatar>
										</h3>
									</div>
									<div>
										<h3 class="mb-0">
											<span
												class="text-muted font-weight-bolder "
												style="font-size:15px"
												>Survey
											</span>
											<b-avatar variant="light-success" size="34">
												<feather-icon icon="CheckSquareIcon" size="25" />
											</b-avatar>
										</h3>
									</div>
									<div>
										<h3 class="mb-0">
											<span
												class="text-muted font-weight-bolder "
												style="font-size:15px"
												>Temps de présence
											</span>
											<b-avatar variant="light-success" size="34">
												100%
											</b-avatar>
										</h3>
									</div>
								</div>
								<div class="col-4">
									<div class="float-right" style="margin-top:6px">
										<b-badge variant="primary" style="margin-right:10px">
											CEU: 1-20-43652
										</b-badge>
										<b-badge variant="danger">
											CFC: 123-654897
										</b-badge>
									</div>
								</div>
							</div>
						</b-media-body>
					</b-media>
				</ul>
				<div class="no-results" :class="{ show: !emails.length }">
					<h5>No Items Found</h5>
				</div>
			</vue-perfect-scrollbar>
		</div>

		<!-- Email View/Detail -->
		<email-view
			:class="{ show: showEmailDetails }"
			:email-view-data="emailViewData"
			:opended-email-meta="opendedEmailMeta"
			@close-email-view="showEmailDetails = false"
			@move-email-to-folder="moveOpenEmailToFolder"
			@toggle-email-starred="toggleStarred(emailViewData)"
			@update-email-label="updateOpenEmailLabel"
			@mark-email-unread="markOpenEmailAsUnread"
			@change-opened-email="changeOpenedEmail"
		/>

		<!-- Sidebar -->
		<portal to="content-renderer-sidebar-left">
			<email-left-sidebar
				:shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
				:show-participants.sync="showParticipants"
				:show-email-details.sync="showEmailDetails"
				:edit-webinar.sync="editWebinar"
				:emails-meta="emailsMeta"
				:class="{ show: mqShallShowLeftSidebar }"
				@close-left-sidebar="mqShallShowLeftSidebar = false"
			/>
		</portal>

		<!-- Compose Email Modal -->
		<email-compose v-model="shallShowEmailComposeModal" />
	</div>
</template>

<script>
import store from "@/store";
import {
	ref,
	onUnmounted,
	computed,
	watch,
	// ref, watch, computed, onUnmounted,
} from "@vue/composition-api";
import {
	// BFormInput,
	// BInputGroup,
	// BInputGroupPrepend,
	//BDropdown,
	//BDropdownItem,
	//BFormCheckbox,
	BBadge,
	BMedia,
	//BMediaAside,
	BMediaBody,
	BAvatar,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { filterTags, formatDateToMonthShort } from "@core/utils/filter";
import { useRouter } from "@core/utils/utils";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import EmailLeftSidebar from "./EmailLeftSidebar.vue";
import EmailView from "./EmailView.vue";
import emailStoreModule from "./emailStoreModule";
import useEmail from "./useEmail";
import EmailCompose from "./EmailCompose.vue";

import WebinarView from "./WebinarView.vue";

//import AppHeaderInfoAdminList from "@/app/views/components/AppHeaderInfoAdminList.vue";
import Ripple from "vue-ripple-directive";
export default {
	components: {
		//AppHeaderInfoAdminList,
		//BFormInput,
		//BInputGroup,
		//BInputGroupPrepend,
		//	BDropdown,
		//	BDropdownItem,
		//BFormCheckbox,
		BMedia,
		//BMediaAside,
		BMediaBody,

		BBadge,
		BAvatar,

		// 3rd Party
		VuePerfectScrollbar,

		// App SFC
		EmailLeftSidebar,
		EmailView,
		EmailCompose,

		WebinarView,
	},
	directives: {
		Ripple,
	},
	setup() {
		const EMAIL_APP_STORE_MODULE_NAME = "app-email";

		// Register module
		if (!store.hasModule(EMAIL_APP_STORE_MODULE_NAME))
			store.registerModule(EMAIL_APP_STORE_MODULE_NAME, emailStoreModule);

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(EMAIL_APP_STORE_MODULE_NAME))
				store.unregisterModule(EMAIL_APP_STORE_MODULE_NAME);
		});

		const { route, router } = useRouter();
		const { resolveLabelColor } = useEmail();

		// Route Params
		const routeParams = computed(() => route.value.params);
		watch(routeParams, () => {
			// eslint-disable-next-line no-use-before-define
			fetchEmails();
		});

		// Emails & EmailsMeta
		const emails = ref([]);
		const emailsMeta = ref({});

		const perfectScrollbarSettings = {
			maxScrollbarLength: 150,
		};

		// Search Query
		const routeQuery = computed(() => route.value.query.q);
		const searchQuery = ref(routeQuery.value);
		watch(routeQuery, (val) => {
			searchQuery.value = val;
		});
		// eslint-disable-next-line no-use-before-define
		watch(searchQuery, () => fetchEmails());
		const updateRouteQuery = (val) => {
			const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query));

			if (val) currentRouteQuery.q = val;
			else delete currentRouteQuery.q;

			router.replace({ name: route.name, query: currentRouteQuery });
		};

		const fetchEmails = () => {
			store
				.dispatch("app-email/fetchEmails", {
					q: searchQuery.value,
					folder: router.currentRoute.params.folder || "inbox",
					label: router.currentRoute.params.label,
				})
				.then((response) => {
					emails.value = response.data.emails;
					emailsMeta.value = response.data.emailsMeta;
				});
		};

		fetchEmails();

		// ------------------------------------------------
		// Mail Selection
		// ------------------------------------------------
		const selectedEmails = ref([]);
		const toggleSelectedMail = (mailId) => {
			const mailIndex = selectedEmails.value.indexOf(mailId);

			if (mailIndex === -1) selectedEmails.value.push(mailId);
			else selectedEmails.value.splice(mailIndex, 1);
		};
		const selectAllEmailCheckbox = computed(
			() =>
				emails.value.length &&
				emails.value.length === selectedEmails.value.length
		);
		const isSelectAllEmailCheckboxIndeterminate = computed(
			() =>
				Boolean(selectedEmails.value.length) &&
				emails.value.length !== selectedEmails.value.length
		);
		const selectAllCheckboxUpdate = (val) => {
			selectedEmails.value = val ? emails.value.map((mail) => mail.id) : [];
		};
		// ? Watcher to reset selectedEmails is somewhere below due to watch dependecy fullfilment

		// ------------------------------------------------
		// Mail Actions
		// ------------------------------------------------
		const toggleStarred = (email) => {
			store
				.dispatch("app-email/updateEmail", {
					emailIds: [email.id],
					dataToUpdate: { isStarred: !email.isStarred },
				})
				.then(() => {
					// eslint-disable-next-line no-param-reassign
					email.isStarred = !email.isStarred;
				});
		};

		const moveSelectedEmailsToFolder = (folder) => {
			store
				.dispatch("app-email/updateEmail", {
					emailIds: selectedEmails.value,
					dataToUpdate: { folder },
				})
				.then(() => {
					fetchEmails();
				})
				.finally(() => {
					selectedEmails.value = [];
				});
		};

		const updateSelectedEmailsLabel = (label) => {
			store
				.dispatch("app-email/updateEmailLabels", {
					emailIds: selectedEmails.value,
					label,
				})
				.then(() => {
					fetchEmails();
				})
				.finally(() => {
					selectedEmails.value = [];
				});
		};

		const markSelectedEmailsAsUnread = () => {
			store
				.dispatch("app-email/updateEmail", {
					emailIds: selectedEmails.value,
					dataToUpdate: { isRead: false },
				})
				.then(() => {
					fetchEmails();
				})
				.finally(() => {
					selectedEmails.value = [];
				});
		};

		// ------------------------------------------------
		// Email Details
		// ------------------------------------------------
		const showEmailDetails = ref(false);
		const emailViewData = ref({});
		const opendedEmailMeta = computed(() => {
			const openedEmailIndex = emails.value.findIndex(
				(e) => e.id === emailViewData.value.id
			);
			return {
				hasNextEmail: Boolean(emails.value[openedEmailIndex + 1]),
				hasPreviousEmail: Boolean(emails.value[openedEmailIndex - 1]),
			};
		});
		const updateEmailViewData = (email) => {
			// Mark email is read
			store
				.dispatch("app-email/updateEmail", {
					emailIds: [email.id],
					dataToUpdate: { isRead: true },
				})
				.then(() => {
					// If opened email is unread then decrease badge count for email meta based on email folder
					if (
						!email.isRead &&
						(email.folder === "inbox" || email.folder === "spam")
					) {
						emailsMeta.value[email.folder] -= 1;
					}

					email.isRead = true;
				})
				.finally(() => {
					emailViewData.value = email;
					showEmailDetails.value = true;
				});
		};
		const moveOpenEmailToFolder = (folder) => {
			selectedEmails.value = [emailViewData.value.id];
			moveSelectedEmailsToFolder(folder);
			selectedEmails.value = [];
			showEmailDetails.value = false;
		};
		const updateOpenEmailLabel = (label) => {
			selectedEmails.value = [emailViewData.value.id];
			updateSelectedEmailsLabel(label);

			// Update label in opened email
			const labelIndex = emailViewData.value.labels.indexOf(label);
			if (labelIndex === -1) emailViewData.value.labels.push(label);
			else emailViewData.value.labels.splice(labelIndex, 1);

			selectedEmails.value = [];
		};

		const markOpenEmailAsUnread = () => {
			selectedEmails.value = [emailViewData.value.id];
			markSelectedEmailsAsUnread();

			selectedEmails.value = [];
			showEmailDetails.value = false;
		};

		const changeOpenedEmail = (dir) => {
			const openedEmailIndex = emails.value.findIndex(
				(e) => e.id === emailViewData.value.id
			);
			const newEmailIndex =
				dir === "previous" ? openedEmailIndex - 1 : openedEmailIndex + 1;
			emailViewData.value = emails.value[newEmailIndex];
		};

		// * If someone clicks on filter while viewing detail => Close the email detail view
		watch(routeParams, () => {
			showEmailDetails.value = false;
		});

		// * Watcher to reset selectedEmails
		// ? You can also use showEmailDetails (instead of `emailViewData`) but it will trigger execution twice in this case
		// eslint-disable-next-line no-use-before-define
		watch([emailViewData, routeParams], () => {
			selectedEmails.value = [];
		});

		// Compose
		const shallShowEmailComposeModal = ref(false);

		// Left Sidebar Responsiveness
		const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();
		const infos = ref({
			title: "Webinar: ",
		});

		const showParticipants = ref(false);
		const editWebinar = ref(true);
		return {
			// UI
			perfectScrollbarSettings,
			infos,
			// Emails & EmailsMeta
			emails,
			emailsMeta,

			// Mail Selection
			selectAllEmailCheckbox,
			isSelectAllEmailCheckboxIndeterminate,
			selectedEmails,
			toggleSelectedMail,
			selectAllCheckboxUpdate,

			// Mail Actions
			toggleStarred,
			moveSelectedEmailsToFolder,
			updateSelectedEmailsLabel,
			markSelectedEmailsAsUnread,

			// Email Details
			showEmailDetails,
			emailViewData,
			opendedEmailMeta,
			updateEmailViewData,
			moveOpenEmailToFolder,
			updateOpenEmailLabel,
			markOpenEmailAsUnread,
			changeOpenedEmail,

			// Search Query
			searchQuery,
			updateRouteQuery,

			// UI Filters
			filterTags,
			formatDateToMonthShort,

			// useEmail
			resolveLabelColor,

			// Compose
			shallShowEmailComposeModal,

			// Left Sidebar Responsiveness
			mqShallShowLeftSidebar,
			showParticipants,
			editWebinar,
		};
	},
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
