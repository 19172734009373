<template>
	<div class="row">
		<b-card no-body class="col-12">
			<b-card-body>
				<h5 class="pb-1">Identité</h5>
				<b-row style="margin-bottom:-8px">
					<b-col md="6" xl="4" class="mb-1">
						<b-form-input placeholder="Nom" v-model="text1" />
					</b-col>
					<b-col md="6" xl="4" class="mb-1">
						<b-form-input placeholder="Prénom" v-model="text1" />
					</b-col>
					<b-col md="6" xl="4" class="mb-1">
						<b-form-input placeholder="Email" v-model="text1" />
					</b-col>
				</b-row>
				<hr />
				<h5 class="pb-1">Numéros certificats</h5>
				<b-row>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-input placeholder="Number CEU" v-model="text1" />
					</b-col>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-input placeholder="Number CFC" v-model="text1" />
					</b-col>
				</b-row>
				<hr />
				<b-row>
					<div class="col-10"></div>
					<div class="col-2">
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							variant="primary"
							block
						>
							Sauvegarder
						</b-button>
					</div>
				</b-row>
			</b-card-body>
		</b-card>

		<b-card>
			<b-card-body>
				<h5 class="pb-1">Réponses au survey</h5>
				<b-list-group>
					<b-list-group-item
						class="flex-column align-items-start"
						v-for="question in surveyData.questions"
						:key="question.id"
					>
						<div class="d-flex w-100 justify-content-between">
							<h5 class="mb-1">
								<b-badge variant="light-primary" style="margin-right:10px">
									{{ question.type }}
								</b-badge>
								{{ question.title }}
							</h5>
						</div>
						<b-card-text class="mb-1">
							<div v-for="choice in question.choices" :key="choice.name">
								<div v-if="choice.userChoice">
									<b-badge variant="success" style="margin-right:10px">
										{{ choice.name }}
									</b-badge>
								</div>
							</div>
						</b-card-text>
					</b-list-group-item>
				</b-list-group>
			</b-card-body>
		</b-card>
	</div>
</template>

<script>
import {
	BRow,
	BCol,
	BCard,
	BCardBody,
	BButton,
	BFormInput,
	BBadge,
	BListGroup,
	BListGroupItem,
} from "bootstrap-vue";
import { formatDate } from "@core/utils/filter";

export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
		BButton,
		BFormInput,
		BBadge,
		BListGroup,
		BListGroupItem,
	},
	props: {},
	setup() {
		const surveyData = {
			id: 5,
			name: "Questionnaire de satisfaction",
			description:
				"Merci de bien vouloir remplir ce questionnaire de la façon la plus honnête possible. Votre avis est très important pour nous, et nous permet de rester dans une amélioration constante de nos formations.",
			type: 1,
			author: "",
			questions: [
				{
					id: 2,
					title:
						"Sur une échelle de 1 à 5 étoiles,  Ce Webinar a-t-il répondu à vos attentes initiales ?",
					infos: null,
					type: "RATING",
					author: "",
					choices: [],
				},
				{
					id: 3,
					title:
						"Est-ce que le jour et l’horaire du webinar que vous avez suivi vous conviennent ?",
					infos: null,
					type: "QCM",
					author: "",
					choices: [
						{ id: 16, name: "Oui", right: false, userChoice: false },
						{ id: 17, name: "Non", right: false, userChoice: true },
					],
				},
				{
					id: 4,
					title: "Quels sont les éléments que vous avez le plus apprécié ?",
					infos: null,
					type: "TEXT_AREA",
					author: "",
					choices: [],
				},
				{
					id: 5,
					title: "Quels sont les éléments que vous avez le moins apprécié ?",
					infos: null,
					type: "TEXT_AREA",
					author: "",
					choices: [],
				},
				{
					id: 6,
					title: "Y-a-t-il un autre sujet que vous aimeriez voir aborder ?",
					infos: null,
					type: "TEXT_AREA",
					author: "",
					choices: [],
				},
				{
					id: 7,
					title: "Recommanderiez-vous ce webinar ?",
					infos: null,
					type: "QCM",
					author: "",
					choices: [
						{ id: 5, name: "Oui", right: false, userChoice: true },
						{ id: 6, name: "Non", right: false, userChoice: false },
					],
				},
				{
					id: 8,
					title:
						"Autorisez-vous ABA Online à reproduire en totalité ou en partie vos commentaires sur ses divers supports (site web, plaquette…) ? Merci de bien noter que nous n’y mentionnerons que votre prénom et l’initiale de votre nom de famille.",
					infos: null,
					type: "QCM",
					author: "",
					choices: [
						{ id: 18, name: "Oui", right: false, userChoice: true },
						{ id: 19, name: "Non", right: false, userChoice: false },
					],
				},
			],
		};
		return {
			formatDate,
			surveyData,
		};
	},
};
</script>

<style></style>
