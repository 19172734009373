<template>
	<b-modal
		id="compose-mail"
		:visible="shallShowEmailComposeModal"
		title="Écrire un mail"
		modal-class="modal-sticky"
		footer-class="d-flex justify-content-between"
		body-class="p-0"
		size="lg"
		no-fade
		hide-backdrop
		static
		@change="(val) => $emit('update:shall-show-email-compose-modal', val)"
	>
		<!-- Modal Header -->
		<template #modal-header>
			<h5 class="modal-title">
				Écrire un mail
			</h5>
			<div class="modal-actions">
				<feather-icon
					icon="MinusIcon"
					class="cursor-pointer"
					@click="$emit('update:shall-show-email-compose-modal', false)"
				/>
				<feather-icon icon="Maximize2Icon" class="ml-1 cursor-pointer" />
				<feather-icon
					icon="XIcon"
					class="ml-1 cursor-pointer"
					@click="discardEmail"
				/>
			</div>
		</template>

		<!-- Modal Footer -->
		<template #modal-footer>
			<!-- Footer: Left Content -->
			<div>
				<b-button
					v-ripple.400="'rgba(255, 255, 255, 0.15)'"
					split
					variant="primary"
					right
					@click="sendEmail"
				>
					Envoyer
				</b-button>
			</div>

			<!-- Footer: Right Content -->
			<div>
				<!-- Dropdown: More Actions -->

				<!-- Discard Compose -->
				<feather-icon
					icon="TrashIcon"
					size="17"
					class="ml-75 cursor-pointer"
					@click="discardEmail"
				/>
			</div>
		</template>

		<!-- Modal: Body -->
		<b-form>
			<!-- Field: Subject -->
			<div class="compose-mail-form-field">
				<label for="email-subject">Sujet: </label>
				<b-form-input id="email-subject" v-model="composeData.subject" />
			</div>

			<!-- Field: Message - Quill Editor -->
			<div class="message-editor">
				<quill-editor
					id="quil-content"
					v-model="composeData.message"
					:options="editorOption"
				/>
				<div id="quill-toolbar" class="d-flex border-bottom-0">
					<!-- Add a bold button -->
					<button class="ql-bold" />
					<button class="ql-italic" />
					<button class="ql-underline" />
					<button class="ql-align" />
					<button class="ql-link" />
				</div>
			</div>
		</b-form>
	</b-modal>
</template>

<script>
import { BButton, BForm, BFormInput } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";
import { quillEditor } from "vue-quill-editor";

export default {
	directives: {
		Ripple,
	},
	components: {
		// BSV
		BButton,

		BForm,
		BFormInput,

		// 3rd Party
		quillEditor,
	},
	model: {
		prop: "shallShowEmailComposeModal",
		event: "update:shall-show-email-compose-modal",
	},
	props: {
		shallShowEmailComposeModal: {
			type: Boolean,
			required: true,
		},
	},
	setup(_, { emit }) {
		const composeData = ref({});
		const showCcField = ref(false);
		const showBccField = ref(false);

		const editorOption = {
			modules: {
				toolbar: "#quill-toolbar",
			},
			placeholder: "Message",
		};

		/* eslint-disable global-require */
		const emailToOptions = [
			{
				avatar: require("@/assets/images/avatars/1-small.png"),
				name: "Jane Foster",
			},
			{
				avatar: require("@/assets/images/avatars/3-small.png"),
				name: "Donna Frank",
			},
			{
				avatar: require("@/assets/images/avatars/5-small.png"),
				name: "Gabrielle Robertson",
			},
			{
				avatar: require("@/assets/images/avatars/7-small.png"),
				name: "Lori Spears",
			},
			{
				avatar: require("@/assets/images/avatars/9-small.png"),
				name: "Sandy Vega",
			},
			{
				avatar: require("@/assets/images/avatars/11-small.png"),
				name: "Cheryl May",
			},
		];
		/* eslint-enable global-require */

		const sendEmail = () => {
			composeData.value = {};
			emit("update:shall-show-email-compose-modal", false);

			// ? Send your Email
		};

		const discardEmail = () => {
			composeData.value = {};
			emit("update:shall-show-email-compose-modal", false);
		};

		return {
			composeData,
			editorOption,
			emailToOptions,
			showCcField,
			showBccField,

			// Email actions
			sendEmail,
			discardEmail,
		};
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss" scoped>
form ::v-deep {
	// Mail To vue-select style
	.v-select {
		.vs__dropdown-toggle {
			border: 0;
			box-shadow: none;
		}
		.vs__open-indicator {
			display: none;
		}
	}

	// Quill Editor Style
	.quill-editor {
		.ql-container.ql-snow {
			border-bottom: 0 !important;
		}
	}
}
</style>
