<template>
	<div class="sidebar-left">
		<div class="sidebar">
			<div class="sidebar-content email-app-sidebar">
				<div class="email-app-menu">
					<vue-perfect-scrollbar
						:settings="perfectScrollbarSettings"
						class="sidebar-menu-list scroll-area"
					>
						<div v-if="showEmailDetails">
							<h2 class="section-label mt-1 mb-2  px-1">
								Sous Menu participant
							</h2>
							<h6 class="section-label mt-1  px-2">
								Certifications
							</h6>

							<b-list-group class="">
								<b-list-group-item>
									<feather-icon
										icon="DownloadCloudIcon"
										size="18"
										class="mr-75"
									/>
									<span class="align-text-bottom line-height-1">
										CEU
									</span>
								</b-list-group-item>
								<b-list-group-item>
									<feather-icon
										icon="DownloadCloudIcon"
										size="18"
										class="mr-75"
									/>
									<span class="align-text-bottom line-height-1">
										CFC
									</span>
								</b-list-group-item>
								<b-list-group-item>
									<feather-icon
										icon="DownloadCloudIcon"
										size="18"
										class="mr-75"
									/>
									<span class="align-text-bottom line-height-1">
										Présence
									</span>
								</b-list-group-item>
							</b-list-group>
							<div class=" text-center compose-btn">
								<b-button
									v-ripple.400="'rgba(255, 255, 255, 0.15)'"
									variant="warning"
									block
									@click="
										$emit('update:shall-show-email-compose-modal', true);
										$emit('close-left-sidebar');
									"
								>
									(Re)générer
								</b-button>
							</div>

							<b-list-group class="">
								<b-list-group-item>
									<b-avatar variant="light-success" size="34">
										80%
									</b-avatar>
									<span class="align-text-bottom line-height-1 pl-2">
										Pre-Quiz
									</span>
								</b-list-group-item>
								<b-list-group-item>
									<b-avatar variant="light-warning">
										<feather-icon icon="XCircleIcon" size="25" />
									</b-avatar>
									<span class="align-text-bottom line-height-1 pl-2">
										Post-Quiz
									</span>
									<b-avatar
										variant="secondary"
										class="float-right"
										v-b-tooltip.hover.right="'Renvoyer le post-Quiz'"
									>
										<feather-icon icon="SendIcon" size="20" />
									</b-avatar>
								</b-list-group-item>
								<b-list-group-item>
									<b-avatar variant="light-success" size="34">
										<feather-icon icon="CheckSquareIcon" size="25" />
									</b-avatar>
									<span class="align-text-bottom line-height-1 pl-2">
										Survey
									</span>
								</b-list-group-item>
							</b-list-group>
						</div>
						<div v-else>
							<b-list-group class="list-group-messages  pt-2">
								<b-list-group-item
									@click="
										$emit('update:show-participants', false);
										$emit('update:edit-webinar', true);
									"
									style="cursor: pointer"
									:active="editWebinar"
								>
									<feather-icon icon="InfoIcon" size="18" class="mr-75" />
									<span class="align-text-bottom line-height-1">
										Webinaire détails
									</span>
								</b-list-group-item>
								<b-list-group-item
									@click="
										$emit('update:show-participants', true);
										$emit('update:edit-webinar', false);
									"
									style="cursor: pointer"
									:active="showParticipants"
								>
									<feather-icon icon="UsersIcon" size="18" class="mr-75" />
									<span class="align-text-bottom line-height-1">
										Liste des participants
									</span>
								</b-list-group-item>
							</b-list-group>

							<div class=" text-center compose-btn">
								<b-button
									v-ripple.400="'rgba(255, 255, 255, 0.15)'"
									variant="primary"
									block
									@click="
										$emit('update:shall-show-email-compose-modal', true);
										$emit('close-left-sidebar');
									"
								>
									Envoyer un Email
								</b-button>
							</div>
							<h6 class="section-label mt-1 mb-1 px-2">
								Portail webinaire
							</h6>
							<div class=" text-center compose-btn" style="margin-top:-15px">
								<b-button
									v-ripple.400="'rgba(255, 255, 255, 0.15)'"
									variant="warning"
									block
								>
									Entrer
								</b-button>
							</div>
						</div>
					</vue-perfect-scrollbar>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
	BButton,
	BListGroup,
	BListGroupItem,
	BAvatar,
	VBTooltip,
} from "bootstrap-vue";
import { isDynamicRouteActive } from "@core/utils/utils";
import Ripple from "vue-ripple-directive";

export default {
	directives: {
		"b-tooltip": VBTooltip,
		Ripple,
	},
	components: {
		// BSV
		BButton,
		BListGroup,
		BListGroupItem,
		BAvatar,

		// 3rd Party
		VuePerfectScrollbar,
	},
	props: {
		shallShowEmailComposeModal: {
			type: Boolean,
			required: true,
		},
		showParticipants: {
			type: Boolean,
			required: true,
		},
		editWebinar: {
			type: Boolean,
			required: true,
		},
		showEmailDetails: {
			type: Boolean,
			required: true,
		},
		emailsMeta: {
			type: Object,
			required: true,
		},
	},
	setup() {
		const perfectScrollbarSettings = {
			maxScrollbarLength: 60,
		};

		const emailFilters = [
			{ title: "Inbox", icon: "MailIcon", route: { name: "apps-email" } },
			{
				title: "Sent",
				icon: "SendIcon",
				route: { name: "apps-email-folder", params: { folder: "sent" } },
			},
			{
				title: "Draft",
				icon: "Edit2Icon",
				route: { name: "apps-email-folder", params: { folder: "draft" } },
			},
			{
				title: "Starred",
				icon: "StarIcon",
				route: { name: "apps-email-folder", params: { folder: "starred" } },
			},
			{
				title: "Spam",
				icon: "InfoIcon",
				route: { name: "apps-email-folder", params: { folder: "spam" } },
			},
			{
				title: "Trash",
				icon: "TrashIcon",
				route: { name: "apps-email-folder", params: { folder: "trash" } },
			},
		];

		const emailLabel = [
			{
				title: "Personal",
				color: "success",
				route: { name: "apps-email-label", params: { label: "personal" } },
			},
			{
				title: "Company",
				color: "primary",
				route: { name: "apps-email-label", params: { label: "company" } },
			},
			{
				title: "Important",
				color: "warning",
				route: { name: "apps-email-label", params: { label: "important" } },
			},
			{
				title: "Private",
				color: "danger",
				route: { name: "apps-email-label", params: { label: "private" } },
			},
		];

		const aboutData = {
			about:
				"Tart I love sugar plum I love oat cake. Sweet ⭐️ roll caramels I love jujubes. Topping cake wafer.",
			joined: "November 15, 2015",
			lives: "New York, USA",
			email: "bucketful@fiendhead.org",
			website: "www.pixinvent.com",
		};

		const options = {
			grid: {
				show: false,
				padding: { left: -15, right: -15, top: -12, bottom: -15 },
			},
			colors: ["#7367f0"],
			plotOptions: {
				radialBar: {
					hollow: { size: "22%" },
					track: { background: "#e9ecef" },
					dataLabels: {
						showOn: "always",
						name: { show: false },
						value: { show: false },
					},
				},
			},
			stroke: { lineCap: "round" },
		};

		const resolveFilterBadgeColor = (filter) => {
			if (filter === "Draft") return "light-warning";
			if (filter === "Spam") return "light-danger";
			return "light-primary";
		};

		return {
			// UI
			perfectScrollbarSettings,
			isDynamicRouteActive,
			resolveFilterBadgeColor,

			// Filter & Labels
			emailFilters,
			emailLabel,
			aboutData,

			options,
		};
	},
};
</script>

<style></style>
