<template>
	<!-- Email Header -->

	<!-- Email Details -->
	<vue-perfect-scrollbar
		:settings="perfectScrollbarSettings"
		class="email-scroll-area scroll-area "
	>
		<!-- Email Thread -->
		<b-card no-body class="col-12">
			<b-card-body>
				<b-media class="mb-2">
					<template #aside>
						<b-avatar
							ref="previewEl"
							:src="userData.avatar"
							:text="avatarText(userData.fullName)"
							size="90px"
							rounded
						/>
					</template>
					<h4 class="mb-1">
						webinar name
					</h4>
					<div class="d-flex flex-wrap">
						<b-button variant="primary" @click="$refs.refInputEl.click()">
							<input
								ref="refInputEl"
								type="file"
								class="d-none"
								@input="inputImageRenderer"
							/>
							<span class="d-none d-sm-inline">Mettre à jour</span>
							<feather-icon icon="EditIcon" class="d-inline d-sm-none" />
						</b-button>
					</div>
				</b-media>

				<h5 class="pb-1">Informations</h5>
				<b-row style="margin-bottom:-8px">
					<b-col md="6" xl="6" class="mb-1">
						<quill-editor :options="editorOption" />
					</b-col>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-input placeholder="Prénom" v-model="text1" />
					</b-col>
				</b-row>
				<hr />
				<h5 class="pb-1">Numéros certificats</h5>
				<b-row>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-input placeholder="Number CEU" v-model="text1" />
					</b-col>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-input placeholder="Number CFC" v-model="text1" />
					</b-col>
				</b-row>
				<hr />
				<b-row>
					<div class="col-10"></div>
					<div class="col-2">
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							variant="primary"
							block
						>
							Sauvegarder
						</b-button>
					</div>
				</b-row>
			</b-card-body>
		</b-card>

		<!-- Email Actions: Reply or Forward -->
	</vue-perfect-scrollbar>
</template>

<script>
import {
	BRow,
	BCol,
	BCard,
	BCardBody,
	BButton,
	BFormInput,
	BMedia,
	BAvatar,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { ref, watch } from "@vue/composition-api";
import useEmail from "./useEmail";
import { avatarText } from "@core/utils/filter";

import { quillEditor } from "vue-quill-editor";
export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
		BButton,
		BFormInput,
		BMedia,
		BAvatar,
		// 3rd Party
		quillEditor,
		VuePerfectScrollbar,
	},
	props: {
		emailViewData: {
			type: Object,
			required: true,
		},
		opendedEmailMeta: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const { resolveLabelColor } = useEmail();

		const perfectScrollbarSettings = {
			maxScrollbarLength: 150,
		};

		const showWholeThread = ref(false);
		const userData = ref({
			id: 2,
			fullName: "Halsey Redmore",
			company: "Skinder PVT LTD",
			role: "author",
			username: "hredmore1",
			country: "Albania",
			contact: "(472) 607-9137",
			email: "hredmore1@imgur.com",
			currentPlan: "team",
			status: "pending",
			avatar: require("@/assets/images/avatars/10.png"),
		});
		watch(
			() => props.emailViewData.id,
			() => {
				showWholeThread.value = false;
			}
		);

		const editorOption = {
			modules: {
				toolbar: "#quill-toolbar",
			},
			placeholder: "Message",
		};

		return {
			// UI
			perfectScrollbarSettings,
			showWholeThread,

			// useEmail
			resolveLabelColor,

			userData,
			avatarText,
			editorOption,
		};
	},
};
</script>

<style></style>
