<template>
	<div class="email-app-details">
		<!-- Email Header -->
		<div class="email-detail-header">
			<!-- Header: Left -->
			<div
				class="email-header-left d-flex align-items-center"
				@click="$emit('close-email-view')"
			>
				<span class="go-back mr-1">
					<feather-icon
						:icon="
							$store.state.appConfig.isRTL
								? 'ChevronRightIcon'
								: 'ChevronLeftIcon'
						"
						size="20"
						class="align-bottom"
					/>
				</span>
				<h4 class="email-subject mb-0">
					Retour
				</h4>
			</div>

			<!-- Header: Right -->
		</div>

		<!-- Email Details -->
		<vue-perfect-scrollbar
			:settings="perfectScrollbarSettings"
			class="email-scroll-area scroll-area mt-3"
		>
			<!-- Email Thread -->
			<b-row>
				<b-col cols="12">
					<email-message-card :message="emailViewData" />
				</b-col>
			</b-row>

			<!-- Email Actions: Reply or Forward -->
		</vue-perfect-scrollbar>
	</div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { ref, watch } from "@vue/composition-api";
import useEmail from "./useEmail";
import EmailMessageCard from "./EmailMessageCard.vue";

export default {
	components: {
		// BSV

		BRow,
		BCol,

		// 3rd Party
		VuePerfectScrollbar,

		// SFC
		EmailMessageCard,
	},
	props: {
		emailViewData: {
			type: Object,
			required: true,
		},
		opendedEmailMeta: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const { resolveLabelColor } = useEmail();

		const perfectScrollbarSettings = {
			maxScrollbarLength: 150,
		};

		const showWholeThread = ref(false);

		watch(
			() => props.emailViewData.id,
			() => {
				showWholeThread.value = false;
			}
		);

		return {
			// UI
			perfectScrollbarSettings,
			showWholeThread,

			// useEmail
			resolveLabelColor,
		};
	},
};
</script>

<style></style>
